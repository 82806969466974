import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { isOldSafari } from '@utils/isOldSafari'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { loadUserSurvey, useSurvey } from '../../contexts/survey'
import { useAuth } from '../../contexts/auth'
import { initAmplitude, trackAmplitudeEvent } from '../../services/client/amplitude'
import { Center } from '../Center'
import { LoadingSpinner } from '../LoadingSpinner'
import { signInAnonymously, User } from '../../contexts/auth/auth'
import Teaser from './Teaser'
import Header from './Header'

const Footer = dynamic(() => import('./Footer'))
const Drawer = dynamic(() => import('./Drawer'))
const VariantA = dynamic(() => import('./VariantA'))
const VariantB = dynamic(() => import('./VariantB'))

const LandingPage: React.FC<{ version: string; bgClassName?: string; pageVariant?: 'a' | 'b' }> = ({
    version,
    bgClassName = 'index-top',
    pageVariant,
    ...props
}) => {
    const router = useRouter()
    const [surveyState, dispatch] = useSurvey()
    const [menuOpen, setMenuOpen] = useState(false)
    const [user, authInitialized] = useAuth()
    const trackedPageVersion = ['a', 'b'].includes(pageVariant as string) ? pageVariant : version
    const landingsTestVariant = user?.amplitudeVariants['landing-optimisation']?.value ?? 'control'

    useEffect(() => {
        if (user) {
            initAmplitude(user.id)
            trackAmplitudeEvent('screen_view', { screen_name: 'landing', version: trackedPageVersion })
            window.ktag('home', true)
            initUserSurvey(user)
        } else if (authInitialized && !user) {
            // Login user anonymously
            signInAnonymously()
        }
    }, [user?.id, authInitialized])

    const initUserSurvey = async (user: User) => {
        const answers = await loadUserSurvey(user)
        dispatch({ type: 'SURVEY_LOADED', user, answers: answers ?? {} })
    }

    const onGoalSelected = (goal: string): void => {
        dispatch({ type: 'QUESTION_ANSWER_CHANGED', name: 'goal', value: goal })
        router.push(
            {
                pathname: '/survey/[[...step]]',
                query: { step: 'number_of_meals' },
            },
            '/survey/number_of_meals',
            { shallow: true },
        )
    }

    const onOpenMenu = (): void => {
        document.body.classList.add('overflow-hidden')
        setMenuOpen(true)
    }

    const onCloseMenu = (): void => {
        document.body.classList.remove('overflow-hidden')
        setMenuOpen(false)
    }

    const onStartJourney = () => {
        dispatch({ type: 'QUESTION_COMPLETED' })
        router.push(
            {
                pathname: '/survey/[[...step]]',
                query: { step: '' },
            },
            '/survey/number_of_meals',
            { shallow: true },
        )
    }
    let bgDivStyle

    if (isOldSafari) {
        bgDivStyle = classNames(
            `relative bg-${bgClassName}-xs-safari md:bg-${bgClassName}-safari bg-cover bg-center overflow-hidden`,
        )
    } else {
        bgDivStyle = classNames(`relative bg-${bgClassName}-xs md:bg-${bgClassName} bg-cover bg-center overflow-hidden`)
    }

    if (pageVariant === 'a') {
        return <VariantA onStartJourneyClick={onStartJourney} />
    }

    if (pageVariant === 'b') {
        return <VariantB onStartJourneyClick={onStartJourney} />
    }

    // default landing
    if (landingsTestVariant === 'control') {
        return (
            <div>
                <div className={bgDivStyle}>
                    <div className="min-h-screen flex justify-center items-center flex-col">
                        <Header onOpenMenu={onOpenMenu} />
                        <Teaser version={version} onGoalSelected={onGoalSelected} />
                    </div>
                </div>
                <Footer version={version} />
                <Drawer version={version} open={menuOpen} onClose={onCloseMenu} />
            </div>
        )
    }
}

export default LandingPage
