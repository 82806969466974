import React from 'react'
import { useTranslation } from 'i18n'
import { FasticLogo } from '@components/FasticLogo'

const Header: React.FC<{ onOpenMenu: () => void }> = React.memo(
    ({ onOpenMenu }) => {
        const { t } = useTranslation()
        return (
            <div className="py-4 z-10 absolute top-0 left-0 w-full bg-black bg-opacity-20">
                <div className="px-5 mx-auto w-full max-w-4xl">
                    <div className="flex justify-between items-center">
                        <a href="/">
                            <FasticLogo className="w-20" textColor="#FFF" />
                            <span className="sr-only">Fastic</span>
                        </a>
                        <div className="hidden md:flex space-x-12 top-0.5 text-white">
                            <a
                                href="https://fastic.com/fasticpedia-2/"
                                className="ml-8 text-lg cursor-pointer duration-150 ease-in-out hover:text-gray-900"
                            >
                                {t('common:menu.faq')}
                            </a>
                            <a
                                href="https://fastic.com/privacy-policy"
                                className="ml-8 text-lg cursor-pointer duration-150 ease-in-out hover:text-gray-900"
                            >
                                {t('common:menu.policy')}
                            </a>
                            <a
                                href="https://fastic.com/terms"
                                className="ml-8 text-lg cursor-pointer duration-150 ease-in-out hover:text-gray-900"
                            >
                                {t('common:menu.terms')}
                            </a>
                        </div>
                        <div onClick={onOpenMenu} className="cursor-pointer flex md:hidden flex-col w-6">
                            <span className="h-0.5 my-0.5 bg-white"></span>
                            <span className="h-0.5 my-0.5 bg-white"></span>
                            <span className="h-0.5 my-0.5 bg-white"></span>
                        </div>
                    </div>
                </div>
            </div>
        )
    },
    () => true,
)

export default Header
