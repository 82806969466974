import React from 'react'
import { useTranslation } from 'i18n'

const Teaser: React.FC<{ version: string; onGoalSelected: (goal: string) => void }> = React.memo(
    ({ version, onGoalSelected }) => {
        const { t } = useTranslation()
        return (
            <>
                <div className="flex flex-col justify-center items-center pt-8 xs:pt-16 flex-grow flex-shrink-0">
                    <div className="px-5 mx-auto w-full max-w-4xl">
                        <div className="text-base xs:text-lg md:text-2xl font-bold mt-24 text-center text-white text-shadow-md">
                            {t([`landing:tagline.${version}`, 'landing:tagline.default'])}
                        </div>
                        <div className="text-xl xs:text-2xl md:text-5xl font-black px-4 mt-4 md:mt-6 text-center text-white text-shadow-md leading-snug">
                            {t([`landing:title.${version}`, 'landing:title.default'])}
                        </div>
                        <div className="text-base xs:text-lg md:text-3xl font-bold px-4 mt-4 md:mt-6 text-center text-white text-shadow-md">
                            {t([`landing:i_want.${version}`, 'landing:i_want.default'])}
                        </div>
                        <img
                            src="/arrow-down.svg"
                            alt="Arrow down"
                            className="w-8 md:w-10 block mx-auto mt-6 md:mt-8 animate-bounce"
                        />
                        <div className="grid grid-cols-2 gap-4 mt-4">
                            <a
                                onClick={() => onGoalSelected('boost_health')}
                                className="inline-block cursor-pointer text-center align-middle select-none px-2 xs:px-3 py-3 leading-tight rounded-lg outline-none duration-150 ease-in-out bg-white text-red-600 font-bold text-sm xs:text-base md:text-lg hover:bg-gray-100"
                            >
                                {t([`landing:options.boost_health.${version}`, 'landing:options.boost_health.default'])}
                            </a>
                            <a
                                onClick={() => onGoalSelected('lose_weight')}
                                className="inline-block cursor-pointer text-center align-middle select-none px-2 xs:px-3 py-3 leading-tight rounded-lg outline-none duration-150 ease-in-out bg-white text-red-600 font-bold text-sm xs:text-base md:text-lg hover:bg-gray-100"
                            >
                                {t([`landing:options.lose_weight.${version}`, 'landing:options.lose_weight.default'])}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="my-3 md:my-5 text-center">
                    <div className="px-5 mx-auto w-full max-w-4xl">
                        <div className="text-center text-xs xs:text-sm md:text-base text-opacity-60 text-white leading-snug mb-2">
                            {t([`landing:warning_text.${version}`, 'landing:warning_text.default'])}
                        </div>
                        <a
                            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6128599/"
                            rel="nofollow"
                            className="underline text-white text-xs md:text-sm"
                        >
                            {t([`landing:warning_link.${version}`, 'landing:warning_link.default'])}
                        </a>
                    </div>
                </div>
            </>
        )
    },
    () => true,
)

export default Teaser
